import React from 'react'
import { EyesProps } from './Types'
import './Styles.scss'
import {
  GridFilterChangeEvent,
  GridFilterOperators,
} from '@progress/kendo-react-grid'
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import DialogEyeComponent from './components/Dialog'
import { useEyeDialogHook } from './components/Dialog/hooks'
import debounce from 'lodash.debounce'
import ExcelComponent from '../../components/excel'
import BulkUploadComponent from '../../components/bulk-upload'
import { SapphireEyeHelper } from './Hooks'
import { Checkbox } from '@progress/kendo-react-inputs/dist/npm/checkbox/Checkbox'
import SevenSignalGrid from '../../shared/7Signal/SevenSignalGrid'
import { SevenSignal } from '../../shared/7Signal/SevenSignalGrid/Types'
import ConfirmationComponent from '../../components/confirm'
import { useConfirmHook } from '../../components/confirm/hooks'
import { useGlobalContext } from '../../commonResources/GlobalContextProvider'

const EyesComponent = (props: EyesProps) => {
  const { eyesContext } = useGlobalContext()

  const dialogHookData = useEyeDialogHook({
    toggleDialog: props.toggleDialog,
    fetchData: props.fetchData,
    accessToken: props.accessToken,
    isEditing: props?.isEditing,
    showDialog: props.showDialog,
    editData: props.editData,
    clearCheckbox: () => {
      eyesContext.itemsSelected = []
      props.setIsEditing(false)
    },
  })

  const confirmProps = useConfirmHook()

  const filterOperators: GridFilterOperators = {
    text: [{ text: 'grid.filterContainsOperator', operator: 'contains' }],
    numeric: [{ text: 'grid.filterEqOperator', operator: 'eq' }],
    date: [{ text: 'grid.filterEqOperator', operator: 'eq' }],
    boolean: [{ text: 'grid.filterEqOperator', operator: 'eq' }],
  }

  const ConditionalCellRender = (td, cellProps) => {
    if (cellProps.field === 'selected') {
      return (
        <td>
          <Checkbox
            data-testid={'eyes-checkbox'}
            onClick={(e: any) => {
              props.onSelectionChange({
                ...cellProps,
                isChecked: e.target.checked,
                dataItems: props?.data,
              })
            }}
            value={eyesContext.itemsSelected.some(
              (item) => item.id === cellProps.dataItem.id
            )}
          />
        </td>
      )
    } else {
      return td
    }
  }

  const HeaderCellRender = (td, cellProps) => {
    if (cellProps.field === 'selected') {
      return <td></td>
    } else {
      return td
    }
  }

  const leftTableButtons: SevenSignal.TableButtons[] = [
    {
      title: 'Add',
      name: 'Add',
      onClick: props.addSapphireEye,
      className: 'eyes-button',
      hasFontAwesomeIcon: true,
      disabled: false,
      icon: faPlus,
    },
    {
      title: 'Edit Changes',
      name: 'Edit',
      onClick: () => props.editSapphireEye(),
      className: 'eyes-button',
      hasFontAwesomeIcon: true,
      disabled: props.shouldDisableEdit(),
      icon: faEdit,
    },
    {
      title: 'Delete Changes',
      name: 'Delete',
      onClick: () => {
        props.handleConfirmDelete(props.editData)
        confirmProps.handleIsVisibleToggle()
      },
      className: 'eyes-button',
      hasFontAwesomeIcon: true,
      disabled: props.shouldDisableButton(),
      icon: faTrash,
    },
  ]

  const rightTableButtons: SevenSignal.TableButtons[] = [
    {
      title: 'Download Excel',
      name: 'Download Excel',
      onClick: () => props.excelDownload(),
      className: 'eyes-button',
      hasFontAwesomeIcon: false,
    },
    {
      title: 'Bulk Upload',
      name: 'Bulk Upload',
      onClick: () => props.toggleBulk(),
      className: 'eyes-button',
      hasFontAwesomeIcon: false,
    },
  ]

  const columns: SevenSignal.Columns[] = [
    {
      field: props.SELECTED_FIELD,
      width: '50px',
      filterable: false,
      headerSelectionValue:
        props.data?.findIndex((item) => !props.idGetter(item)) === -1,
    },
    {
      field: 'macAddress',
      title: 'Mac Address',
    },
    {
      field: 'organization.name',
      title: 'Organization',
    },
    {
      field: 'serialNumber',
      title: 'Serial Number',
    },
    {
      field: 'createdAt',
      title: 'Created',
      filterable: false,
      format: '{0: M/d/yyyy h:mm a}',
    },
    {
      field: 'updatedAt',
      title: 'Updated',
      filterable: false,
      format: '{0: M/d/yyyy h:mm a}',
    },
  ]

  return (
    <div className="page" data-testid={'eyes-table'}>
      <h1 className="page-header__text">Sapphire Eyes</h1>
      <SevenSignalGrid
        customCellRender={ConditionalCellRender}
        customHeaderCellRender={HeaderCellRender}
        sortable={{
          allowUnsort: true,
          mode: 'single',
        }}
        sort={props.sort}
        skip={props.page.skip}
        take={props.page.take}
        total={props.pageSizeValue}
        data={props.data?.map((item) => ({
          ...item,
          createdAt: new Date(item.createdAt),
          updatedAt: new Date(item.updatedAt),
        }))}
        dataItemKey={'id'}
        rowHeight={50}
        selectedField={props.SELECTED_FIELD}
        selectable={{
          enabled: true,
          drag: false,
          cell: false,
          mode: 'multiple',
        }}
        onSelectionChange={props.onSelectionChange}
        onHeaderSelectionChange={props.onHeaderSelectionChange}
        onPageChange={props.pageChange}
        filterable={true}
        filter={props.filter}
        filterOperators={filterOperators}
        onFilterChange={debounce(
          (e: GridFilterChangeEvent) => props.handleFilter(e.filter),
          100
        )}
        handleDisable={props.shouldDisableButton()}
        handleSortChange={props.sortChange}
        handleFilter={props.handleFilter}
        leftTableButtons={leftTableButtons}
        rightTableButtons={rightTableButtons}
        columns={columns}
        sortChange={props.sortChange}
        idGetter={props.idGetter}
      />

      {confirmProps.confirmIsVisible && (
        <ConfirmationComponent
          message={props.confirmDeleteMessage}
          onAbort={() => {
            confirmProps.handleIsVisibleToggle()
          }}
          onConfirm={() => {
            props.deleteSapphireEye()
            confirmProps.handleIsVisibleToggle()
          }}
        ></ConfirmationComponent>
      )}

      {props.showDialog && <DialogEyeComponent {...dialogHookData} />}
      <ExcelComponent
        data={props.data}
        fields={[
          {
            name: 'macAddress',
            title: 'Mac Address',
          },
          {
            name: 'organization.name',
            title: 'Organization Name',
          },
          {
            name: 'serialNumber',
            title: 'Serial Number',
          },
        ]}
      />
      {props.showBulk && (
        <BulkUploadComponent
          toggleBulk={props.toggleBulk}
          organizationList={props.organizationList}
          organization={props.organization}
          handleChange={(val, list) => {
            const id = list.find((item) => item.name === val.name)?.id
            SapphireEyeHelper.bulkRequestId = id
            props.setOrganization(val)
          }}
          handleBulkRequest={props.handleBulkRequest}
          handleBeforeBulkRequest={props.handleBeforeBulkRequest}
          bulkRequest={props.bulkRequest}
        />
      )}
    </div>
  )
}

export default EyesComponent

import React, { createContext, useContext, useState } from 'react'

const GlobalContext = createContext({})
export const useGlobalContext: any = () => useContext(GlobalContext)

const GlobalContextProvider = ({ children }) => {
  const [eyesContext, setEyesContext] = useState<any>({
    itemsSelected: [],
    newEye: {
      macAddress: '',
      serialNumber: '',
      organization: undefined,
    },
  })
  const [instanceContext, setInstanceContext] = useState<any>({
    itemsSelected: [],
  })
  const [organizationContext, setOrganizationContext] = useState<any>({
    itemsSelected: [],
  })

  return (
    <GlobalContext.Provider
      value={{
        eyesContext,
        setEyesContext,
        instanceContext,
        setInstanceContext,
        organizationContext,
        setOrganizationContext,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalContextProvider
